<template>
  <div class="gxm">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">我的桂信码</div>
    <!-- 已认证 -->
    <div v-if="auth" class="card1 card">
      <img :src="store.state.imgURL + url" alt="">
      <div><span @click="downloadGxm()">下载</span></div>      
    </div>
    <!-- 未认证 -->
    <div v-else class="card2 card">
      <img src="../assets/img/gxm2.png" alt="">
      <div>企业未认证，未生成桂信码</div>      
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { apiGetGXM,apiDownloadGXM } from '../api/axios';
import { useStore } from 'vuex';
import { Toast } from 'vant';
export default {
   setup() {
     let store = useStore();
     const auth = ref(true);
     const url = ref('');
     apiGetGXM({}).then(res => {
        console.log(res,'返回的数据')                   
        if(res.code == '1'){
            // console.log('下载附件')
            url.value = res.info.imgUrl;
        }
        else {
            auth.value = false;
        }
    });
    const downloadGxm = () => {
      apiDownloadGXM({}).then(res => {
        console.log(res,'返回的数据')                   
        if(res.code == '1'){
            console.log('下载erweima')
            // url.value = res.info.imgUrl;
        }
        else {
            Toast(res.msg);
        }
      });
    }
    return { 
      store,
      auth,
      url,
      downloadGxm
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.gxm {
  padding-top: 12.5vw;
  .card1 {
    text-align: center;
    img {
      width: 66vw;
      height: auto;
    }
    div {
      text-align: center;
      margin: 4vw;
      span {
        background: linear-gradient(to right, #ffc700, #e49600);
        color: #fff;
        border: 0;
        padding: 1.5vw 6vw;
        border-radius: 4vw;
        font-size: 4vw;
      }
    }
  }
  .card2 {
    text-align: center;
    img {
      width: 30vw;
      height: auto;
      margin: 18vw 0;
    }
    div {
      text-align: center;
      font-size: 4vw;
      margin-bottom: 10vw;
    }
  }
}
</style>
